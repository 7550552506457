import React, { useCallback, useEffect, useState } from "react";
import { useDebounce } from "@shared/hooks";
import { Icon } from "@shared/components";

import "./index.scss";

export interface InputSearchProps extends React.HTMLProps<HTMLInputElement> {
  onChangeSearch: (value: string) => void;
  showClearText?: boolean;
  showClose?: boolean;
  onChangeShowSearch?: (value: boolean) => void;
}

const InputSearch = (props: InputSearchProps) => {
  const { onChangeSearch, value, showClose, onChangeShowSearch, ...inputProps } = props;
  const [inputValue, setInputValue] = useState<string>(value ? String(value) : "");
  const [showSearch, setShowSearch] = useState(!showClose);
  const debounceValue = useDebounce<string>(inputValue, 500);

  useEffect(() => {
    setInputValue(value ? String(value) : "");
    if (value) {
      setShowSearch(true);
    }
  }, [value]);

  useEffect(() => {
    onChangeShowSearch && onChangeShowSearch(showSearch);
  }, [onChangeShowSearch, showSearch]);

  useEffect(() => {
    if (debounceValue !== value && debounceValue !== undefined) {
      onChangeSearch(debounceValue);
    }
  }, [debounceValue, onChangeSearch, inputValue, value]);

  const onChangeInput = (event: React.SyntheticEvent) => {
    setInputValue((event.target as HTMLInputElement).value);
  };

  const clearInput = useCallback(() => {
    setInputValue("");
  }, []);

  return (
    <div className="search custom-input">
      <>
        <Icon type="find" onClick={() => setShowSearch(true)} className="search-icon-block" />
        <input className="input-search" {...inputProps} onChange={onChangeInput} value={inputValue} />
        {inputValue ? (
          <div className="search-controls">
            <div className="clear-text" onClick={clearInput}>
              <Icon type="clear" />
            </div>
          </div>
        ) : null}
      </>
    </div>
  );
};

export default InputSearch;
